import { createSlice } from '@reduxjs/toolkit';
import blogimage1 from '../assests/images/bannerImage1.webp'
import blogimage2 from '../assests/images/how-to-use-lightning-empApi-in-ightning-web-component1.webp'
import blogimage3 from '../assests/images/how-to-import-data-in-salesforce-from-excel-sheet1.webp'
import blogimage4 from '../assests/images/how-to-send-mass-emails-to-all-users1.webp'
import blogimage5 from '../assests/images/how-to-secure-your-salesforce-web-to-lead-forms-with-captcha1.webp'
import blogimage6 from '../assests/images/streamlining-business-operations-the-complete-guide-to-salesforce-and-quickBooks-integration1.webp'
import blogimage7 from '../assests/images/lead-generate-from-instagram-to-salesforce-using-zapier.webp';
import blogimage8 from '../assests/images/how-to-create-a-record-from-the-salesforce-site-page-and-make-it-accessible-to-users-outside-of-your-salesforce-organization.webp';
import blogimage9 from '../assests/images/salesforce-platform-event-implementing-publish-and-subscribe-with-apex-code-events.webp'
import blogimage10 from '../assests/images/authorize-and-manage-salesforce-data-with-postman-crud-operations.webp'
import blogimage11 from '../assests/images/the-role-of-trigger-context-variables-in-apex-triggers.webp'
import blogimage12 from '../assests/images/how-to-communicate-between-unrelated-components-in-LWC.webp';
import blogimage13 from '../assests/images/pushTopic-in-salesforce-real-time-updates-for-custom-apps.webp';
import blogimage14 from '../assests/images/utilizing-automation-workflow-processes-triggers-during-salesforce-data-import.webp';
import blogimage15 from '../assests/images/exploring-change-data-capture-techniques.webp'
import blogimage16 from '../assests/images/leveraging-the-standard-lightning-modal-for-simple-pop-ups-in-salesforce.webp'
import blogimage17 from '../assests/images/building-dynamic-record-picker-component-in-lwc.webp';
import blogimage18 from '../assests/images/how-to-use-uiRecordApi-to-create-update-delete-the-record-in-lwc.webp';
import blogimage19 from '../assests/images/how-to-use-graphqlapi-in-lightning-web-component.webp';
import blogimage20 from '../assests/images/Screenshot 2024-07-02 185737.png';
import blogimage21 from '../assests/images/how-to-solve-a-parent-account-can-not-be-the-child-of-an-account-it-is-already-a-parent-of-error-In-salesforce.webp'

const BLOGDATA = {
  "blogdata":[
    {
      id:1,
      img:blogimage1,
      slug:"validation-rules-in-salesforce-a-comprehensive-guide",
      url:"blogs/validation-rules-in-salesforce-a-comprehensive-guide.html",
      title:"Validation Rules in Salesforce: A Comprehensive Guide",
      date:"25/01/2024",
      description:"Briefly introduce the significance of validation rules in Salesforce and how they contribute to data integrity and accuracy.",
    },
    {  
      id:2,
      img:blogimage2,
      slug:"how-to-use-lightningempApi-in-lightning-web-component",
      url:"blogs/how-to-use-lightningempApi-in-lightning-web-component.html",
      title:"How to use lightning/empApi in Lightning web components",
      date:"26/01/2024",
      description:"In this blog we will understand how can we use lighting/empApi. Basically lightning/empApi provides us to access methods for streaming channel",
    },
    {  
      id:3,
      img:blogimage3,
      slug:"how-to-import-data-in-salesforce-from-excel-sheet",
      url:"blogs/how-to-import-data-in-salesforce-from-excel-sheet.html",
      title:"How to import data in salesforce from excel sheet",
      date:"15/02/2024",
      description:"Importing data into Salesforce from an Excel sheet is a common requirement for organizations looking to streamline their data management processes.",
    },
    {  
      id:4,
      img:blogimage4,
      slug:"how-to-send-mass-emails-to-all-users",
      url:"blogs/how-to-send-mass-emails-to-all-users.html",
      title:"How to send mass emails to all users",
      date:"22/02/2024",
      description:"In the dynamic landscape of business, effective communication is the cornerstone of success. For Salesforce users, maintaining seamless interaction with all team members is paramount.",
    },
    {  
      id:5,
      img:blogimage5,
      slug:"how-to-secure-your-salesforce-web-to-lead-forms-with-captcha",
      url:"blogs/how-to-secure-your-salesforce-web-to-lead-forms-with-captcha.html",
      title:"How to secure your salesforce web to lead forms with captcha",
      date:"22/02/2024",
      description:"A Web-to-Lead form is a feature in Salesforce that allows businesses to capture lead information directly from their website. It's a form that you embed on your website, typically on",
    },
    {  
      id:6,
      img:blogimage6,
      slug:"streamlining-business-operations-the-complete-guide-to-salesforce-and-quickBooks-integration",
      url:"blogs/streamlining-business-operations-the-complete-guide-to-salesforce-and-quickBooks-integration.html",
      title:"Streamlining Business Operations: The Complete Guide to Salesforce and QuickBooks Integration",
      date:"04/03/2024",
      description:"In today's rapidly evolving business landscape, organizations are constantly seeking innovative ways",
    },
    {  
      id:7,
      img:blogimage7,
      slug:"lead-generate-from-instagram-to-salesforce-using-zapier",
      url:"blogs/lead-generate-from-instagram-to-salesforce-using-zapier.html",
      title:"Lead Generate From Instagram To Salesforce Using Zapier",
      date:"05/03/2024",
      description:"Zapier is a popular automation tool that allows users to connect different apps and automate workflows without the need for coding.",
    },
    {  
      id:8,
      img:blogimage8,
      slug:"how-to-create-a-record-from-the-salesforce-site-page-and-make-it-accessible-to-users-outside-of-your-salesforce-organization",
      url:"blogs/how-to-create-a-record-from-the-salesforce-site-page-and-make-it-accessible-to-users-outside-of-your-salesforce-organization.html",
      title:"How to Create a Record from the Salesforce Site Page and make it Accessible to users outside of your Salesforce organization",
      date:"09/03/2024",
      description:"Salesforce Sites is a feature provided by Salesforce that allows you to create public websites and applications that are directly integrated with your Salesforce data and processes.",
    },
    {  
      id: 9,
      img:blogimage9,
      slug:"salesforce-platform-event-implementing-publish-and-subscribe-with-apex-code-events",
      url:"blogs/salesforce-platform-event-implementing-publish-and-subscribe-with-apex-code-events.html",
      title:"Salesforce Platform Event: Implementing Publish and Subscribe with Apex Code Events",
      date:"11/03/2024",
      description:"Welcome to the future of Salesforce development – where real-time communication, seamless integration, and unparalleled efficiency converge.",
    },
    {  
      id:10,
      img:blogimage10,
      slug:"authorize-and-manage-salesforce-data-with-postman-crud-operations",
      url:"blogs/authorize-and-manage-salesforce-data-with-postman-crud-operations.html",
      title:"Authorize and Manage Salesforce Data with Postman: CRUD Operations",
      date:"26/03/2024",
      description:"Authorize and Manage Salesforce Data with Postman: CRUD Operations",
    },
    {  
      id:11,
      img:blogimage11,
      slug:"the-role-of-trigger-context-variables-in-apex-triggers",
      url:"blogs/the-role-of-trigger-context-variables-in-apex-triggers.html",
      title:"The Role of Trigger Context Variables in Apex Triggers",
      date:"28/03/2024",
      description:"Trigger context variables in Salesforce Apex are collections of data that provide specific information about the context in which a trigger is executed.",
    },
    {  
      id:12,
      img:blogimage12,
      slug:"how-to-communicate-between-unrelated-components-using-lightning-message-service",
      url:"blogs/how-to-communicate-between-unrelated-components-using-lightning-message-service.html",
      title:"How to communicate between unrelated components in Lightning Message Service",
      date:"12/04/2024",
      description:"Lightning Message Service (LMS) is a way to communicate between unrelated Lightning Webc Components.",
    },
    {  
      id:13,
      img:blogimage13,
      slug:"pushTopic-in-salesforce-real-time-updates-for-custom-apps",
      url:"blogs/pushTopic-in-salesforce-real-time-updates-for-custom-apps.html",
      title:"PushTopic in Salesforce: Real-Time Updates for Custom Apps",
      date:"06/05/2024",
      description:"It's a tool that lets you get notified about specific changes happening to your data",
    },
    {  
      id:14,
      img:blogimage14,
      slug:"utilizing-automation-workflow-processes-triggers-during-salesforce-data-import",
      url:"blogs/utilizing-automation-workflow-processes-triggers-during-salesforce-data-import.html",
      title:"Utilizing automation Workflow/Processes/Triggers during Salesforce Data Import",
      date:"10/05/2024",
      description:"The Data Import Wizard is a tool that helps you bring data into a system easily.",
    },
    {  
      id:15,
      img:blogimage15,
      slug:"exploring-change-data-capture-techniques",
      url:"blogs/exploring-change-data-capture-techniques.html",
      title:"Exploring change data capture techniques",
      date:"23/05/2024",
      description:"Change Data Capture represents the changes in salesforce records",
    },
    {  
      id:16,
      img:blogimage16,
      slug:"leveraging-the-standard-lightning-modal-for-simple-pop-ups-in-salesforce",
      url:"blogs/leveraging-the-standard-lightning-modal-for-simple-pop-ups-in-salesforce.html",
      title:"Leveraging the Standard Lightning Modal for Simple Pop-Ups in Salesforce",
      date:"06/06/2024",
      description:"A modal is a specific type of popup that interrupts the user's workflow",
    },
    {  
      id:17,
      img: blogimage17,
      slug:"building-dynamic-record-picker-component-in-lwc",
      url:"blogs/building-dynamic-record-picker-component-in-lwc.html",
      title:"Building dynamic record picker component in lwc",
      date:"11/06/2024",
      description:"The lightning-record-picker lets you search and choose Salesforce records",
    },
    {  
      id:18,
      img:blogimage18,
      slug:"how-to-use-uiRecordApi-to-create-update-delete-the-record-in-lwc",
      url:"blogs/how-to-use-uiRecordApi-to-create-update-delete-the-record-in-lwc.html",
      title:"How to use uiRecordApi to Create, Update and Delete the record in lwc",
      date:"28/06/2024",
      description:"This module includes wire adapters to record data and get",
    },
    {  
      id:19,
      img:blogimage19,
      slug:"how-to-use-graphqlapi-in-lightning-web-component",
      url:"blogs/how-to-use-graphqlapi-in-lightning-web-component.html",
      title:"How to use GraphQL Api in lightning web component",
      date:"01/07/2024",
      description:"GraphQL is a query language for your API, and a server-side runtime for",
    },
    {  
      id:20,
      img:blogimage20,
      slug:"how-to-use-lightning-logger-module-in-lwc",
      url:"blogs/how-to-use-lightning-logger-module-in-lwc.html",
      title:"How to use lightning logger module in LWC",
      date:"02/07/2024",
      description:"The lightning/logger module provides a function to add",
    },
    {  
      id:21,
      img:blogimage21,
      slug:"how-to-solve-a-parent-account-can-not-be-the-child-of-an-account-it-is-already-a-parent-of-error-In-salesforce",
      url:"blogs/how-to-solve-a-parent-account-can-not-be-the-child-of-an-account-it-is-already-a-parent-of-error-In-salesforce.html",
      title:"How to solve \"A parent account can't be the child of an account it's already a parent of\" error In salesforce",
      date:"24/01/2025",
      description:"In Salesforce, accounts can be linked in a parent-child hierarchy. The parent account is the higher-level account.",
    },
]}

BLOGDATA.blogdata.sort((a, b) => 
    b.id - a.id
);

const blogSlice = createSlice({
    name:'blog',
    initialState:{data:BLOGDATA}
})

export const blogActions = blogSlice.actions;

export default blogSlice;
